import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { EasementCoordinate, EasementFeature, Segment } from 'pages/widgetDashboard/components/easementCenterline/types/easement';
import { Map, View } from 'ol'
import { ATTRIBUTE_FILTER_TOOL, CENTER, EXTENT_FILTER_TOOL, GEOMETRY_FILTER_TOOL, TOOLBAR_ITEMS } from '../constants/map';
import { MAX_ZOOM } from '../constants/map';
import { LayerListItem } from 'types/layer-list-item';
import { SingleSelectionToolbarItem, ToolbarItem } from 'types/toolbar-item';
import VectorSource from 'ol/source/Vector';
import { Geometry } from 'ol/geom';
import { AttributeQueryParam } from 'types/attribute-filter';
import { source } from '@trcsolutions/ol-react'
import prepareLayerList from 'helpers/prepareLayerList';
import { WidgetInstanceData } from 'types/widget-instance';
import { v4 as uuid } from "uuid";

const initialMap = new Map({
    view: new View({
        center: CENTER,
        zoom: 5,
        maxZoom: MAX_ZOOM,
    }),
});

const initalLayerState = {
    layerList: [],
    activeLayer: null
}

const initialToolbarItems = TOOLBAR_ITEMS.map((item) => ({ ...item, ...(item.selection === "multi" && { isActive: false }) })) as Array<ToolbarItem>

const initialControlState = {
    showLayerList: false,
    showToolbar: false,
    showTileBar: false,
    toolbarItems: initialToolbarItems,
    selectedDrawGeometryTool: null
}

type InitialEasementState = {
    currentEasementFeature: EasementFeature | null;
    easementCoordinateList: Array<EasementCoordinate>;
    showPdfPreview: boolean;
    isPreviewFormDirty: boolean;
    showPreviewFormChangeModal: boolean;
    isFormReset: boolean;
    nextAction: (() => void) | null;
    map: Map;
    layer: {
        layerList: Array<LayerListItem>;
        activeLayer: LayerListItem | null
    };
    control: {
        showLayerList: boolean;
        showToolbar: boolean;
        showTileBar: boolean;
        toolbarItems: Array<ToolbarItem>;
        selectedDrawGeometryTool: SingleSelectionToolbarItem | null
    };
    features: Array<any>;
    selectedFeatureId: string;
    drawSource: VectorSource<Geometry>;
    drawGeometry: Geometry | null;
    attributeQueryParams: Array<AttributeQueryParam>;
    extentGeometry: string;
    selectedFilter: string | null;
    zoomLevel: number;
    locationQuery: string;
    isMouseOverMap: boolean;
}

const initialState: InitialEasementState = {
    currentEasementFeature: null,
    easementCoordinateList: [],
    showPdfPreview: true,
    isPreviewFormDirty: false,
    nextAction: null,
    showPreviewFormChangeModal: false,
    isFormReset: false,
    map: initialMap,
    layer: initalLayerState,
    control: initialControlState,
    features: [],
    selectedFeatureId: "",
    drawSource: source.vector({ wrapX: false }),
    drawGeometry: null,
    attributeQueryParams: [],
    extentGeometry: "",
    selectedFilter: null,
    zoomLevel: initialMap?.getView().getZoom() ?? 0,
    locationQuery: "",
    isMouseOverMap: false
}

export const easementCenterlineSlice = createSlice({
    name: 'easementCurve',
    initialState,
    reducers: {
        resetEasementInstance: () => initialState,
        setCurrentEasementFeature: (state, action: PayloadAction<EasementFeature | null>) => {
            state.currentEasementFeature = action.payload
        },
        setEasementCoordinateList: (state, action: PayloadAction<Array<EasementCoordinate>>) => {
            state.easementCoordinateList = action.payload.map(item => ({ ...item, id: uuid() }))
        },
        addEasementCoordinate: (state, action: PayloadAction<EasementCoordinate>) => {
            state.easementCoordinateList.push({
                id: uuid(), ...action.payload
            })
        },
        updateEasementCoordinate: (state, action: PayloadAction<EasementCoordinate>) => {
            const index = state.easementCoordinateList.findIndex(c => c.seq_no === action.payload.seq_no)
            state.easementCoordinateList[index] = { ...state.easementCoordinateList[index], ...action.payload }
        },
        deleteEasementCoordinate: (state, action: PayloadAction<number>) => {
            state.easementCoordinateList = state.easementCoordinateList.filter(c => c.seq_no !== action.payload).map((c, i) => ({ ...c, seq_no: i + 1 }))
        },
        moveEasementCoordinate: (state, action: PayloadAction<{ fromIndex: number, toIndex: number }>) => {
            const { fromIndex, toIndex } = action.payload
            const updatedRows = [...state.easementCoordinateList];
            const [movedRow] = updatedRows.splice(fromIndex, 1);
            updatedRows.splice(toIndex, 0, movedRow);
            state.easementCoordinateList = updatedRows.map((c, i) => ({ ...c, seq_no: i + 1 }))
        },
        setShowPdfPreview: (state, action: PayloadAction<boolean>) => {
            state.showPdfPreview = action.payload
        },
        setIsPreviewFormDirty: (state, action: PayloadAction<boolean>) => {
            state.isPreviewFormDirty = action.payload
        },
        setIsFormReset: (state, action: PayloadAction<boolean>) => {
            state.isFormReset = action.payload
        },

        setShowPreviewFormChangeModal: (state, action: PayloadAction<boolean>) => {
            state.showPreviewFormChangeModal = action.payload
        },
        setNextAction: (state, action: PayloadAction<(() => void) | null>) => {
            state.nextAction = action.payload
        },
        setMap: (state, action: PayloadAction<Map>) => {
            state.map = action.payload
        },
        setFeatures: (state, action: PayloadAction<any>) => {
            state.features = action.payload
        },
        setLayerList: (state, action: PayloadAction<Array<LayerListItem>>) => {
            state.layer.layerList = action.payload
            const layerListItem = state.layer.layerList.find(x => x.id === state.layer.activeLayer?.id)
            if (layerListItem) {
                state.layer.activeLayer = layerListItem
            }
        },
        setActiveLayer: (state, action: PayloadAction<LayerListItem>) => {
            state.layer.activeLayer = action.payload
            state.selectedFeatureId = ""
        },
        setShowLayerList: (state, action: PayloadAction<boolean>) => {
            state.control.showLayerList = action.payload
        },
        setShowToolbar: (state, action: PayloadAction<boolean>) => {
            state.control.showToolbar = action.payload
        },
        setShowTilebar: (state, action: PayloadAction<boolean>) => {
            state.control.showTileBar = action.payload
        },
        toggleToolbarItem: (state, action: PayloadAction<string>) => {
            const toolbarItems = state.control.toolbarItems
            const item = toolbarItems.find(x => x.name === action.payload)
            if (item && item.selection === "multi") {
                item.isActive = !item.isActive
            }
        },
        setToolbarItemActiveStatus: (state, action: PayloadAction<{ name: string, isActive: boolean }>) => {
            const toolbarItems = state.control.toolbarItems
            const item = toolbarItems.find(x => x.name === action.payload.name)
            if (item && item.selection === "multi")
                item.isActive = action.payload.isActive
        },
        setSelectedFeatureId: (state, action: PayloadAction<string>) => {
            state.selectedFeatureId = action.payload
        },
        setSelectedDrawGeometryTool: (state, action: PayloadAction<SingleSelectionToolbarItem | null>) => {
            if (action.payload && state.control.selectedDrawGeometryTool?.name === action.payload.name) {
                state.control.selectedDrawGeometryTool = null
            } else {
                state.control.selectedDrawGeometryTool = action.payload
            }
        },
        setDrawGeometry: (state, action: PayloadAction<Geometry | null>) => {
            state.drawGeometry = action.payload
        },
        setAttributeQueryParams: (state, action: PayloadAction<Array<AttributeQueryParam>>) => {
            state.attributeQueryParams = action.payload
        },
        setExtentGeometry: (state, action: PayloadAction<string>) => {
            state.extentGeometry = action.payload
        },
        setSelectedFilter: (state, action: PayloadAction<string | null>) => {
            state.selectedFilter = action.payload
        },
        clearFiltersByTool: (state, action: PayloadAction<string>) => {
            const tool = action.payload
            if (tool !== GEOMETRY_FILTER_TOOL) {
                state.drawSource.clear();
                if (state.drawGeometry) state.drawGeometry = null;
            }
            if (tool !== ATTRIBUTE_FILTER_TOOL) {
                if (state.attributeQueryParams.length) state.attributeQueryParams = [];
            }
            if (tool !== EXTENT_FILTER_TOOL) {
                if (state.extentGeometry) state.extentGeometry = "";
            }
            state.control.selectedDrawGeometryTool = null;
            state.selectedFilter = null;
        },
        processLayerConfiguration: (state, action: PayloadAction<WidgetInstanceData>) => {
            const layerList = prepareLayerList(action.payload)
            state.layer.layerList = [...layerList]
            if (state.layer.layerList.length) {
                state.layer.layerList[0].isChecked = true
                state.layer.activeLayer = state.layer.layerList[0]
            }
        },
        setZoomLevel: (state, action: PayloadAction<number>) => {
            state.zoomLevel = action.payload
        },
        setLocationQuery: (state, action: PayloadAction<string>) => {
            state.locationQuery = action.payload
        },
        setIsMouseOverMap: (state, action: PayloadAction<boolean>) => {
            state.isMouseOverMap = action.payload
        },
    },
})

export const { resetEasementInstance, setCurrentEasementFeature, setEasementCoordinateList, setShowPdfPreview,
    addEasementCoordinate, deleteEasementCoordinate, updateEasementCoordinate, setIsPreviewFormDirty, setIsFormReset, setShowPreviewFormChangeModal,
    setMap, setFeatures, setLayerList, setActiveLayer, setShowLayerList, setNextAction, moveEasementCoordinate,
    setShowToolbar, setShowTilebar, setToolbarItemActiveStatus, toggleToolbarItem, setSelectedFeatureId, setSelectedDrawGeometryTool,
    setDrawGeometry, setAttributeQueryParams, setExtentGeometry, setSelectedFilter, processLayerConfiguration, setZoomLevel, setLocationQuery,
    clearFiltersByTool, setIsMouseOverMap
} = easementCenterlineSlice.actions

export default easementCenterlineSlice.reducer

